.wrap {
  height: 30px;
  width: 100px;
  background: theme('colors.gray200');
  border-radius: 18px;
  position: relative;
  transition: background 0.3s ease;
}

.isActive {
  background: theme('colors.primary');
}

.isActive .knob {
  transform: translate(70px, 0);
}

.knob {
  height: 26px;
  width: 26px;
  background: #fff;
  border-radius: 100px;
  position: absolute;
  left: 2px;
  top: 2px;
  transition: transform 0.3s ease;
}

.label {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}
