.wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
}

.categoryImageTextBox {
  max-width: calc(50% - 8px);
  margin: 4px;
  overflow: hidden;

  @media (min-width: 768px) {
    max-width: calc(25% - 16px);
    margin: 8px;
  }
}

.imgFullHeight {
  height: 100%;
  overflow: hidden;

  div {
    transition: all 0.7s ease;

    @media (min-width: 1024px) {
      &:hover {
        transition: all 0.7s ease;
        transform: scale(1.1);
      }
    }
  }
}

.buttonWrapper {
  margin-top: 4px;
}

.button {
  justify-content: flex-start;

  @media (max-width: 575px) {
    font-size: 12px;
    padding: 8px 12px;
    height: 40px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 12px;
    padding: 8px 12px;
    height: 40px;
  }
}

.eaButton {
  background-color: theme('colors.inputBackground');
  color: theme('colors.black');
}

.iconWidth {
  margin-left: 4px;

  @media (max-width: 575px) {
    width: 14px !important;
    margin-left: 2px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 14px !important;
    margin-left: 2px;
  }
}
