.iconWrap > svg path {
  fill: currentColor;
}

.iconWrap > svg {
  height: 100%;
  width: 100%;
}

.iconWrap--color-primary {
  :global path:not(.noFillColorChange) {
    fill: theme("colors.primary");
  }
}

.iconWrap--color-darkBlue {
  :global path:not(.noFillColorChange) {
    fill: theme('colors.darkBlue');
  }
}

.iconWrap--color-green {
  :global path:not(.noFillColorChange) {
    fill: theme("colors.success");
  }
}

.iconWrap--color-green-neon {
  :global path:not(.noFillColorChange) {
    fill: theme("colors.greenNeon");
  }
}

.iconWrap--color-white {
  :global path:not(.noFillColorChange) {
    fill: theme("colors.white");
  }
}

.iconWrap--color-red {
  :global path:not(.noFillColorChange) {
    fill: theme("colors.danger");
  }
}
