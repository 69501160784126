.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: theme('spacing.5') theme('spacing.6');
  background-color: theme('colors.primary');
  color: theme('colors.white');
  text-align: left;
}

.headline {
  text-transform: uppercase;
  font-weight: 600;
}

.subHeadline {
  font-size: 14px;
}

.amount {
  margin-left: auto;
  margin-right: theme('spacing.12');
  font-weight: bold;
}

.content {
  background-color: #f1f1f1;
  padding: theme('spacing.5') theme('spacing.6');
}

.hasShiftedContent {
  margin-left: theme('spacing.6');
}

.openButton {
  background-color: theme('colors.white');
  border-radius: 100px;
  display: inline-flex;
  padding: theme('spacing.1');

  @media (min-width: 1024px) {
    background: transparent;
  }
}

.chevronIcon {
  transform-origin: center center;
  transform: rotate(90deg);
  transition: transform 0.3s ease-in-out;
}

.wrapIsOpen .chevronIcon {
  transform: rotate(270deg);
}
