.wrap {
  margin-left: theme('spacing.8');
}

.changeButton {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: theme('spacing.1');
  display: inline-flex;
}

.changeButton:hover {
  text-decoration: none;
}

.searchWrap {
  border: 1px solid theme('colors.darkBlue');
}

.searchInput {
  border: none;
  width: 100%;
  border-bottom: 1px solid theme('colors.darkBlue');
  padding: theme('spacing.2') theme('spacing.8');
  font-weight: 600;
  color: theme('colors.fontColor');
  margin-bottom: -1px;
  font-size: 14px;
}

.searchInput::-webkit-search-decoration,
.searchInput::-webkit-search-cancel-button,
.searchInput::-webkit-search-results-button,
.searchInput::-webkit-search-results-decoration {
  -webkit-appearance: none;
  border-radius: 0;
}

.searchInput::placeholder {
  font-weight: 600;
  color: theme('colors.fontColor');
  font-size: 14px;
}

.searchInput:focus {
  outline: none;
  box-shadow: none;
  border-color: theme('colors.darkBlue');
}

.searchResultsWrap {
  background-color: theme('colors.gray50');
  max-height: 280px;
  overflow: scroll;
  padding: 0 theme('spacing.8');
}

.searchResultItem {
  position: relative;
  display: block;
  padding: theme('spacing.3') 0;
  border-bottom: 1px solid theme('colors.darkBlue');
  text-align: left;
  cursor: pointer;

  > span {
    display: block;
  }
}

.searchResultItem:last-child {
  border-bottom: none;
}

.searchResultItemCity {
  font-weight: 600;
}

.searchResultItemCheckboxWrap {
  position: absolute;
  right: theme('spacing.2');
  bottom: theme('spacing.2');
}

.doneButtonWrap {
  display: flex;
  justify-content: flex-end;
  margin-top: theme('spacing.2');
}

.selectedItem {
  background: theme('colors.white');
  border: 1px solid theme('colors.darkBlue');
  padding: theme('spacing.2') theme('spacing.8');
  margin-top: theme('spacing.2');
}

.selectedItemZip {
  font-weight: 600;
}

.searchInputWrap {
  position: relative;
}

.removeButton {
  cursor: pointer;
  background: theme('colors.darkBlue');
  height: 22px;
  width: 22px;
  border-radius: 100px;
  position: absolute;
  text-transform: uppercase;
  right: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  top: 50%;
  transform: translate(0, -50%);
}

.searchButtonWrap {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 theme('spacing.3');
  border-left: 1px solid theme('colors.darkBlue');
}
