.markdownWrap > * {
  margin: revert;
  padding: revert;
  font-size: revert;
  font-weight: revert;
}

.customFont > * {
  font-size: inherit;
  line-height: 1;
}

.disableMargin {
  p:first-of-type {
    margin: unset;
  }
}
