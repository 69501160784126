.criteria {
  position: relative;
  padding-left: theme('spacing.5');
  font-size: 12px;
}

.criteriaValid {
  position: absolute;
  left: 0;
  top: 50%;
  width: 15px;
  height: 15px;
  font-size: 12px;
  font-weight: 500;
  background-color: theme('colors.gray100');
  transform: translateY(-50%);
  color: theme('colors.success');

  &:before {
    content: 'L';
    display: block;
    text-align: center;
    transform: translate(0, -4px) rotateY(160deg) rotate(-45deg);
  }
}

.criteriaInvalid {
  font-size: 14px;
  color: theme('colors.danger');

  &:before {
    content: 'x';
    transform: translate(0, -5px);
  }
}
