.backdrop {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(33, 33, 33, 0.8);
  z-index: 1001;
  position: fixed;
}

.modalContainer {
  box-shadow: 0 25px 24px -12px #2a2a2a;
  border: 1px solid theme('colors.lightBlue');
  border-radius: 2px;
  background: theme('colors.white');

  position: fixed;
  top: 50%;
  left: 50%;
  overflow: auto;
  transform: translate(-50%);
  max-width: 480px;
  width: calc(100% - 40px);
  min-height: 150px;
  max-height: calc(100vh - 80px - 40px);

  display: flex;
  flex-direction: column;
  z-index: 1002;
}

.scrollContainer {
  padding: 28px 26px;
  overflow-y: auto;
  height: 100%;
}

.title {
  font-weight: 800;
  color: theme('colors.darkBlue');
  margin-bottom: theme('spacing.3');
  text-transform: uppercase;
  font-size: 22px;
  line-height: 26px;
}

.content {
  color: theme('colors.darkBlue');
}

.closeButton {
  height: 40px;
  width: 40px;
  border-radius: 100px;
  top: 8px;
  right: 8px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeIcon {
  height: 24px;
  width: 24px;
  position: relative;
}

.closeIcon:before {
  content: '';
  position: absolute;
  height: 20px;
  background: theme('colors.gray500');
  width: 1px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.closeIcon:after {
  content: '';
  height: 20px;
  width: 1px;
  position: absolute;
  left: 50%;
  background: #888;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}
