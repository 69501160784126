.inputWrap {
  background: theme('colors.inputBackground');
  display: inline-flex;
  width: 100%;
  position: relative;
}

.input {
  border: none;
  background: none;
  font-size: 15px;
  color: theme('colors.fontColor');
  appearance: none;
  width: 100%;
  -webkit-appearance: none;

  &[type="search"]::-webkit-search-decoration,
  &[type="search"]::-webkit-search-cancel-button,
  &[type="search"]::-webkit-search-results-button,
  &[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}

.input::placeholder {
  font-size: 15px;
  color: theme('colors.fontColor');
  opacity: 0;
}

.has-error .input {
  color: theme('colors.danger');
}

.input:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

.infoIconWrap {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translate(0, -50%);
  display: inline-flex;
  z-index: 2;
  transition: all 200ms ease-in-out;

  &:hover {
    opacity: 0.6;
  }
}

.infoIconOuterWrap {
  right: auto;
  left: calc(100% + 4px);
}

.input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.placeholder {
  color: theme('colors.fontColor');
  font-size: 14px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 12px;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
  z-index: 1;
  max-width: calc(100% - 50px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input:focus + .placeholder,
.input-has-content .placeholder {
  font-size: var(--placeholder-focus-font-size, 11px);
  font-weight: 500;
  transform: translate(0, calc(-50% - var(--placeholder-focus-transform, 10px)));
}

.style--grey {
  background: #f2f2f2;
}

.style--white {
  background: theme('colors.white');
  border: 1px solid theme('colors.gray400');
}

.numberAsTextInput {
  input {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
}

.errorMessage {
  color: theme('colors.danger');
  font-size: 13px;
  font-weight: 400;
}

.modalContent p + p {
  margin-top: 16px;
}

.modalContent a:link,
.modalContent a:visited {
  text-decoration: underline;
}

.statusWrap {
  height: 28px;
  width: 28px;
  border-radius: 100%;
  transform: translate(0, -50%);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  position: absolute;
  right: 8px;
  top: 50%;
}

.errorIcon, .successIcon {
  width: 20px;
  height: 20px;
  position: relative;
}

.errorIconCustomStyle:before,
.errorIconCustomStyle:after {
  top: unset !important;
}

.errorIcon:before,
.errorIcon:after {
  content: '';
  height: 20px;
  width: 3px;
  position: absolute;
  top: theme('spacing.1');
  left: 50%;
  transform: translate(0, 0);
  background: theme('colors.danger');
  transform-origin: center center;
}

.errorIcon:before {
  transform: rotate(45deg);
}

.errorIcon:after {
  transform: rotate(-45deg);
}

.successIcon:before,
.successIcon:after {
  content: '';
  position: absolute;
  background-color: theme('colors.success');
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}

.successIcon:before {
  top: 50%;
  height: 50%;
  width: 3px;
}

.successIcon:after {
  bottom: 0;
  height: 3px;
  width: 100%;
}
